<template>
  <CalendarApp1 v-if="!$can('admin_calendar_access', 'all')"></CalendarApp1>
  <AdminCalendar v-else></AdminCalendar>
</template>

<script lang="js">
import {defineComponent, onMounted} from "vue";
import CalendarApp1 from "@/components/calendar/CalendarApp1.vue";
import AdminCalendar from "@/components/admin-calendar/index.vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "calendar",
  components: {
    CalendarApp1,
    AdminCalendar
  },
  mounted() {
    setCurrentPageTitle("My Calendar");
  }
});
</script>
