<template>
  <div class="card">
    <div class="card-header">
      <h2 class="card-title fw-bolder">Calendar</h2>
      <div class="card-toolbar"></div>
    </div>
    <div class="card-body">
      <LoadingSpinner v-if="loading"></LoadingSpinner>
      <FullCalendar
        class="demo-app-calendar"
        :options="calendarOptions"
        v-if="calendarOptions && !loading"
      ></FullCalendar>
    </div>
  </div>
  <NewEventModal></NewEventModal>
</template>

<script>
import { defineComponent } from "vue";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import events, {TOMORROW} from "@/core/data/events";
import { TODAY } from "@/core/data/events";
import NewEventModal from "@/components/modals/forms/NewEventModal.vue";
import { Modal } from "bootstrap";
import { ref } from 'vue';
import {mapActions, mapGetters} from "vuex";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue"

export default defineComponent({
  name: "calendar-app-1",
  components: {
    FullCalendar,
    NewEventModal,
    LoadingSpinner
  },
  data() {
    return {
      calendarOptions: null
    }
  },
  methods: {
    ...mapActions('CalendarModule', ['fetchShifts']),
    handleEventClick(data) {
      this.$router.push(`/jobs/${data.event.extendedProps.job_id}/shifts`);
    }
  },
  computed: {
    ...mapGetters('CalendarModule', ['shifts', "loading"])
  },
  watch: {
    shifts(val) {
      if (val.length) {
        const newEvent = () => {
          const modal = new Modal(document.getElementById("kt_modal_add_event"));
          modal.show();
        };

        const calendarOptions = {
          plugins: [dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin],
          headerToolbar: {
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay"
          },
          initialDate: TODAY,
          firstDay: '1',
          navLinks: true, // can click day/week names to navigate views
          weekNumbers: true,
          eventClick: this.handleEventClick,
          weekText: 'Week ',
          views: {
            timeGridWeek: { buttonText: "week" },
            timeGridDay: { buttonText: "day" },
          },

          editable: false,
          dayMaxEvents: true, // allow "more" link when too many events
          events: this.shifts,
        };

        this.calendarOptions = calendarOptions
      }
    }
  },
  beforeMount() {
    this.fetchShifts();
  },
});
</script>

<style lang="scss">
.fc .fc-button {
  padding: 0.75rem 1.25rem;
  box-shadow: none !important;
  border: 0 !important;
  border-radius: 0.475rem;
  vertical-align: middle;
  font-weight: 500;
  text-transform: capitalize;
}
.fc-daygrid-event {
  cursor: pointer !important;
}
.fc-event-main {
  cursor: pointer !important;
}
.shift-event-header {
  font-size: 0.85em;
}
.fc-daygrid-day-frame {
  min-height: 150px !important;
}
.fc-event i {
  width: 100%;
}
</style>
